import React from 'react'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Img from 'gatsby-image'

import { Layout, Map, SEO, useModalForm } from '@/components'
import IconEmail from '@/assets/images/svg/email.svg'
import IconPhone from '@/assets/images/svg/phone.svg'
import IconPoint from '@/assets/images/svg/point.svg'

import { TicketForm } from './components'
import { useFileImages } from './hooks'

const marker = { lat: 49.4170029, lng: 27.0126862 }

export const Main = ({ pageContext: { seo } }) => {
  const { isLoaded } = useModalForm()
  const { mainBg, houseImage, busImage, kasaLogo } = useFileImages()

  return (
    <Layout>
      <SEO
        description={seo.metaDesc}
        title={seo.title}
        meta={[
          {
            name: `keywords`,
            content: seo.focuskw,
          },
        ]}
      />
      <div className="main-header">
        <Img
          fluid={mainBg.childImageSharp.fluid}
          objectFit="cover"
          style={{
            position: 'absolute',
            zIndex: 0,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          }}
        />
        <div className="container main-header__wrpr">
          <h1 className="title main-header__title">
            <b>Оренда</b> та&nbsp;<b>замовлення</b> автобусів
          </h1>
          <p className="text main-header__text">
            ПП “АТП ПОДІЛЛЯ-ТУР”- це компанія, яка відповідає за{' '}
            <b>комфорт, сервіс та безпеку</b> пасажирів
          </p>
          <div className="main-header__btns">
            <AnchorLink href="#ticket" className="btn btn_gradient">
              Забронювати квиток
            </AnchorLink>
            <Link to="/calculator/" className="btn">
              Розрахувати вартість проїздки
            </Link>
          </div>
        </div>
      </div>
      <section id="ticket" className="section main-tickets">
        <div className="container">
          <h2 className="title_h2">Квитки на автобус</h2>
          <div id="booking" className="main-tickets_box">
            <TicketForm logo={kasaLogo} />
          </div>
          <div id="app"></div>
        </div>
      </section>
      <div className="section main-rent">
        <div className="main-rent__wrpr">
          <div className="container">
            <div className="main-rent__box-big">
              <h2 className="title_h2">
                Оренда автобуса в&nbsp;
                <span className="text_blue">«АТП&nbsp;ПОДІЛЛЯ-ТУР»</span>
              </h2>
              <div className="quotes">
                <h3 className="title_h3 quotes_text">
                  Мандрівки навчають нас більше, ніж все інше. Іноді один день,
                  проведений в іншому місті, дає набагато більше, ніж
                  десятиліття
                </h3>
                <div className="quotes_auth">©Анатоль Франс</div>
              </div>
              <p className="text">
                Нас кличе у мандри дорога! А можливо не у мандри, а на весілля
                чи іншу цікаву подію: зустріч однокласників, екскурсія і т. д.
                Щоб враження від поїздки залишилися максимально приємними, а
                дорога запам'яталась комфортом, потрібно замовити автобус у «АТП
                ПОДІЛЛЯ-ТУР».
              </p>
              <p className="text">
                Нас кличе у мандри дорога! А можливо не у мандри, а на весілля
                чи іншу цікаву подію: зустріч однокласників, екскурсія і т. д.
                Щоб враження від поїздки залишилися максимально приємними, а
                дорога запам'яталась комфортом, потрібно замовити автобус у «АТП
                ПОДІЛЛЯ-ТУР».
              </p>
            </div>
          </div>
          <div className="main-rent__img main-rent__img_bus">
            <Img
              fluid={busImage.childImageSharp.fluid}
              imgStyle={{ objectFit: 'contain' }}
              style={{
                position: 'absolute',
                zIndex: 0,
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              }}
            />
          </div>
        </div>
        <div className="main-rent__wrpr">
          <div className="container">
            <h3 className="title_h3">
              5 переваг оренди автобуса в&nbsp;«АТП&nbsp;ПОДІЛЛЯ-ТУР»:
            </h3>
            <div className="flex-box">
              <div className="main-rent__box-big">
                <p className="text">
                  1. Можливість самостійно <b>розрахувати вартість оренди</b>{' '}
                  автобуса для школи чи відрядження.
                </p>
                <p className="text">
                  2. Компетентні консультанти, які{' '}
                  <b>зателефонують через 30 секунд</b> після залишеної заявки.
                  Ви не витрачатимете цінний робочий час, хвилини обідньої
                  перерви чи вихідного на пошуки. Ми найоперативніше підберемо
                  найкращий варіант.
                </p>
                <p className="text">
                  3. Автопарк з <b>різних моделей</b>. Ми перевеземо від сотні
                  гостей на весілля до кількох ділових партнерів на важливі
                  переговори. І всі залишаться задоволеними орендою
                  мікроавтобуса. Місткість транспорту від 18 до 60 місць
                  дозволяє це зробити якісно та безпечно. Заздалегідь оцінити
                  зовнішній вигляд автобусу та його салону можна на фото на
                  сайті. Вони оригінальні та відповідають наявному автопарку.
                </p>
              </div>
              <div className="main-rent__box">
                <p className="text">
                  4. <b>Досвід.</b> За багаторічну роботу ми возили школярів на
                  екскурсії, родичів на весілля, туристів за кордон, ділових
                  людей на міжнародні виставки, лижників та сноубордистів на
                  гірськолижні курорти Карпат. На свято чи по роботі кожен
                  клієнт{' '}
                  <span className="text_blue">«АТП&nbsp;ПОДІЛЛЯ-ТУР»</span>{' '}
                  доїде в гарному настрої та в максимальному комфорті.
                </p>
                <p className="text">
                  5. <b>Задоволені клієнти</b>.{' '}
                  <span className="text_blue">«АТП&nbsp;ПОДІЛЛЯ-ТУР»</span> вже
                  перевіз сотні тисяч задоволених пасажирів по області, Україні
                  та в близькому зарубіжжі.{' '}
                  <b>Нам довіряють, про нас пам'ятають, нас рекомендують</b>{' '}
                  колегам, рідним, друзям, знайомим.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="main-rent__wrpr">
          <div className="container">
            <div className="main-rent__box-big">
              <h3 className="title_h3">
                Замовити автобус дешево - не мрія, а реальність!
              </h3>
              <p className="text">
                Ми з розумінням ставимось до потреб кожного клієнта. Тому на
                сайті пропонуємо зручну послугу <b>онлайн-калькулятор</b>{' '}
                <span className="text_blue">“Розрахунок поїздки”</span>. Тепер
                не потрібно клієнту тримати в голові всі цифри. Адже з&nbsp;
                <span className="text_blue">«АТП&nbsp;ПОДІЛЛЯ-ТУР»</span> все:
              </p>
              <p className="text">
                <b>- Прозоро.</b> Перед тим, як замовити автобус, самі вводите
                параметри та маєте готову суму. А значить, орієнтуєтесь у
                вартості послуги та самостійно плануєте власний бюджет;
              </p>
              <p className="text">
                <b>- Зрозуміло.</b> Без складних коефіцієнтів, прихованих формул
                чи запитів. Пару кліків, і сума перед вами;
              </p>
              <p className="text">
                <b>- Легко.</b> Оренда автобуса з&nbsp;
                <span className="text_blue">«АТП&nbsp;ПОДІЛЛЯ-ТУР»</span>{' '}
                перестала бути головною проблемою. Тепер будь-хто може знайти
                зручний, комфортний, бюджетний транспорт на всі випадки життя.
              </p>
            </div>
          </div>
          <div className="main-rent__img main-rent__img_house">
            <Img
              fluid={houseImage.childImageSharp.fluid}
              imgStyle={{ objectFit: 'cover' }}
              style={{
                position: 'absolute',
                zIndex: 0,
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              }}
            />
          </div>
        </div>
      </div>
      <section className="section main-contacts">
        <div className="container">
          <h2 className="title_h2">Контактна інформація</h2>
          <div className="flex-box">
            <div className="main-contacts_box">
              <div className="main-contacts_card">
                <div className="main-contacts_icon">
                  <IconPhone className="icon" />
                </div>
                <a href="tel:0687706030" className="link">
                  +38(068)770-60-30;
                </a>
              </div>
              <div className="main-contacts_card">
                <div className="main-contacts_icon">
                  <IconEmail className="icon" />
                </div>
                <a href="mailto:horkavuj@ukr.net" className="link">
                  horkavuj@ukr.net
                </a>
              </div>
              <div className="main-contacts_card">
                <div className="main-contacts_icon">
                  <IconPoint className="icon" />
                </div>
                <div className="link">
                  м. Хмельницький, <br />
                  вул. Шевченка, 64, офіс 5
                </div>
              </div>
            </div>
            <div id="map" className="main-contacts_map">
              {isLoaded && <Map center={marker} markers={[marker]} zoom={15} />}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
