import React, { useState } from 'react'
import Img from 'gatsby-image'
import Flatpickr from 'react-flatpickr'
import languages from 'flatpickr/dist/l10n'

import LogoKasa from '@/assets/images/svg/logo_kasa.svg'
import IconSearch from '@/assets/images/svg/search.svg'
import { Autocomplete } from '@/components'
import { useCities } from '@/hooks'

import { useTicketData } from '../../hooks'

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
  return (
    <input
      {...props}
      className="input input-date"
      name="date"
      placeholder="Дата"
      defaultValue={defaultValue}
      ref={inputRef}
    />
  )
}

export const TicketForm = ({ logo }) => {
  const cities = useCities()
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [date, setDate] = useState(null)

  const _handleSubmit = (e) => {
    e.preventDefault()
    if (from && to && date) {
      window.open(
        `https://kasa.lux-reisen.com/booking/?f=${from.cityId}&t=${to.cityId}&d=${date}`
      )
    }
  }

  return (
    <>
      <form action="" className="main-tickets_form">
        <div className="kasa-logo">
          <Img
            fluid={logo.childImageSharp.fluid}
            objectPosition="50% 50%"
            imgStyle={{ objectFit: 'contain' }}
            style={{ height: '100%', borderWidth: 2 }}
          />
        </div>
        <div className="main-tickets_input-box">
          <Autocomplete
            options={cities}
            getOptionLabel={(option) => option.name}
            name="from"
            placeholder="Місто відправлення"
            onChange={(value) => setFrom(value)}
          />
          <Autocomplete
            options={cities}
            getOptionLabel={(option) => option.name}
            name="to"
            placeholder="Місто прибуття"
            onChange={(value) => setTo(value)}
          />
          <Flatpickr
            data-enable-time
            value=""
            onChange={(selectedDates, dateStr, instance) => {
              setDate(instance.formatDate(selectedDates[0], 'Y-m-d'))
            }}
            options={{
              minDate: 'today',
              locale: languages.uk,
              dateFormat: 'd.m.Y',
            }}
            render={({ defaultValue, value, ...props }, ref) => {
              return <CustomInput defaultValue={defaultValue} inputRef={ref} />
            }}
          />
        </div>
        <button
          className="btn btn_gradient btn_find"
          onClick={(e) => _handleSubmit(e)}
        >
          <span>
            <IconSearch />
            Знайти
          </span>
        </button>
      </form>
    </>
  )
}
