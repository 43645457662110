import { useStaticQuery, graphql } from 'gatsby'

export const useFileImages = () => {
  const images = useStaticQuery(
    graphql`
      query {
        mainBg: file(relativePath: { eq: "main-bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440, maxHeight: 900, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        houseImage: file(relativePath: { eq: "house.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        busImage: file(relativePath: { eq: "rent-bus.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        kasaLogo: file(relativePath: { eq: "kasa-logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return images
}
